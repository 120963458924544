export default [
	{
		icon: "bi-filetype-jpg",
		name: "jpg",
		contentType: "image/jpeg"
	},
	{
		icon: "bi-filetype-jpg",
		name: "jpeg",
		contentType: "image/jpeg"
	},
	{
		icon: "bi-filetype-png",
		name: "png",
		contentType: "image/png"
	},
	{
		icon: "bi-filetype-gif",
		name: "gif",
		contentType: "image/gif"
	},
	{
		icon: "bi-filetype-tiff",
		name: "tiff",
		contentType: "image/tiff"
	},
	{
		icon: "bi-filetype-mp4",
		name: "mp4",
		contentType: "video/mp4"
	},
	{
		icon: "bi-filetype-mp3",
		name: "mp3",
		contentType: "audio/mp3"
	},
	{
		icon: "bi-filetype-mp3",
		name: "mp3",
		contentType: "audio/mpeg"
	},
	{
		icon: "bi-filetype-txt",
		name: "txt",
		contentType: "text/plain"
	},
	{
		icon: "bi-filetype-pdf",
		name: "pdf",
		contentType: "application/pdf"
	},
	{
		icon: "bi-filetype-docx",
		name: "docx",
		contentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
	},
	{
		icon: "bi-filetype-doc",
		name: "doc",
		contentType: "application/msword"
	},
	{
		icon: "bi-filetype-ppt",
		name: "ppt",
		contentType: "application/vnd.ms-powerpoint"
	},
	{
		icon: "bi-filetype-pptx",
		name: "pptx",
		contentType: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
	},
	{
		icon: "bi-filetype-xls",
		name: "xls",
		contentType: "application/vnd.ms-excel"
	},
	{
		icon: "bi-filetype-xlsx",
		name: "xlsx",
		contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	},
	{
		icon: "bi-file-zip",
		name: "rar",
		contentType: "application/x-rar"
	},
	{
		icon: "bi-file-zip",
		name: "zip",
		contentType: "application/x-zip-compressed"
	},
	{
		icon: "bi-file-zip",
		name: "zip",
		contentType: "application/zip"
	}
];
